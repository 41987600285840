<template>
  <div class="form-group">
    <div class="row">
      <div class="col">
        <label for="sms-body">{{$t('generic-str.content')}}</label>
      </div>
      <!-- <div class="col text-right">
        <div class="popup-box-container dropdown">
          <a href="javascript:void(0)" @click="addVariable()">
            <span class="badge btn-yup-purple">+{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span>
          </a>
        </div>
      </div> -->
    </div>
    <textarea
      class="form-control whats-input-text-area"
      rows="6"
      id="sms-body"
      @keyup="countSegments"
      :value="value"
      @input="setValue($event.target.value)"
    ></textarea>
    <div class="textarea-information bg-light text-right">
      <small class="form-text text-muted">
        <b>{{ length }}</b> {{$t('sms.send-msg.sms-input-component.lbl-chars')}}.
      </small>
    </div>
    <div class="variable-wrapper mt-4">
      <button class="btn btn-add-var text-yup" @click="addVariable()">
        Adicionar novo parâmetro
        <span class="material-symbols-outlined">add</span>
      </button>
      <!-- <span class="text-yup mr-1">Adicionar novo parâmetro</span>
      <button class="btn btn-outline-primary btn-icon-add" @click="addVariable()">
        <span class="material-icons"> add </span>
      </button> -->
    </div>
  </div>
</template>

<script>
import Emojis from '@/components/Emojis.vue';

const GSM = /^[ -~\n]*$/;

export default {
  name: 'WhatsInput',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    paramsChange: {
      type: Function,
    },
    normalize: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      length: 0,
      hasVar: false,
      varNumber: 0,
      nextValue: 0,
      lastValue: 1,
    };
  },
  watch: {
    value() {
      this.countSegments();
    },
    normalize() {
      this.countSegments();
    },
  },
  methods: {
    insertAtCursor(myValue) {
      const myField = document.querySelector('.whats-input-text-area');
      if (myField.selectionStart || myField.selectionStart === '0') {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
      } else {
        myField.value += myValue;
      }
      this.setValue(myField.value);
    },
    setValue(value) {
      this.$emit('input', value);
    },
    countSegments() {
      this.length = this.value.length;
    },
    addVariable() {
      const count = (this.value.match(/{{(.*?)}}/g) || []).length;
      this.nextValue = count + 1;
      this.insertAtCursor(`{{${this.nextValue}}}`);
      this.countSegments();
      this.paramsChange(this.nextValue);
    },
  },
};
</script>

<style lang="scss">
.popup-box-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
textarea:not(:last-child) {
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */

.btn-icon-add {
  padding: 10px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  span {
    font-size: 1rem;
  }
}

.btn-add-var {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-weight: 400;
  span {
    border-style: solid;
    border-width: 1px;
    border-color: var(--clr-yup-purple);
    border-radius: 50%;
    transition: .5s;
  }
  &:hover {
    span {
      color: #fff;
      background-color: var(--clr-yup-purple);
    }
  }
}

.variable-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
}

.whats-input-text-area {
  resize: none;
}
</style>
