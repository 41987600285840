<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Preview</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="data">
            <div class="form-group">
              <div class="row">
                <div class="col-lg-12"  v-if="form.template.body">
                  <label for="sms-body">Preview</label>
                </div>
                <div class="col-lg-12" v-if="form.template.body">
                  <div class="whats-container">
                    <div class="whats-preview">
                      <div class="relative">
                        <video style="width: 100%;" v-if="form.template.header && form.template.header.format === 'VIDEO'">
                          <source :src="`${form.template.header.example.header_handle[0]}`">
                        </video>
                        <img id="img-preview" :src="`${form.template.header.example.header_handle[0]}`" v-if="form.template.header && form.template.header.format === 'IMAGE'"/>
                      </div>
                      <p class="whats-preview-header" v-if="form.template.header && form.template.header.format === 'TEXT'">{{form.template.header.text}}</p>
                      <p class="whats-preview-body">{{form.template.body}}</p>
                      <p class="whats-preview-footer" v-if="form.template.footer">{{form.template.footer.text}}</p>
                    </div>
                    <div class="row" v-if="form.template.buttons">
                      <div class="col-sm-12" v-for="(btn, i) in form.template.buttons" :key="i">
                        <button type="button" class="btn-link-preview" v-if="btn.type == 'PHONE_NUMBER'"><i class="fas fa-phone"></i> {{btn.text}}</button>
                        <button type="button" class="btn-link-preview" v-if="btn.type == 'URL'"><i class="fas fa-link"></i> {{btn.text}}</button>
                        <button type="button" class="btn-link-preview" v-if="btn.type == 'QUICK_REPLY'">{{btn.text}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hide">
            {{$t('generic-str.close')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: { template: {} },
    };
  },
  mounted() {
  },
  created() {
    this.$root.$on('updated.previewwhats', this.insertTemplate);
  },
  methods: {
    insertTemplate(template) {
      this.form.template = template;
      this.form.template.name = template.name;
      console.log(template);
      template.components.forEach((element) => {
        console.log(element);
        this.form.template.type = 'text';
        if (element.type === 'BODY') {
          this.form.template.body = element.text;
        }
        if (element.type === 'HEADER') {
          this.form.template.header = element;
          this.form.template.type = 'rich';
          if (element.format === 'IMAGE') {
            this.form.template.type = 'image';
          }
        }
        if (element.type === 'FOOTER') {
          this.form.template.footer = element;
          this.form.template.type = 'rich';
        }
        if (element.type === 'BUTTONS') {
          this.form.template.buttons = element.buttons;
          this.form.template.type = 'rich';
        }
        // const count = (this.value.match(/{{(.*?)}}/g) || []).length;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.preview-action-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}
.preview-action-buttons .div-reply{
  z-index: 1024;
  align-items: center;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .15);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 5px;
}
</style>
