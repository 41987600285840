<template>
  <div class="content">
    <!-- <div class="testing-container">
      <p>{{template.name}}</p>
    </div> -->
    <PageHeader title="WhatsApp" :subtitle="$t('template-component.new')" />
    <section class="page-content container-fluid mb-3">
      <div class="row">
        <!-- Content col -->
        <div class="col-8 left-content-column">
          <h2>Categoria</h2>
          <span>Escolha uma categoria que melhor descreva seu modelo de mensagem</span>
          <div class="accordion accordion-wpp-template" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-1 variant="info">Accordion 1</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-1'), changeCategory('MARKETING')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">campaign</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Marketing</p>
                      <span>Promoções ou informações sobre sua empresa, produtos ou serviços. Ou qualquer mensagem que não seja relevante ou de autenticação.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-1-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false" checked>
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>
                    <ul class="list-unstyled">
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-2'), changeCategory('UTILITY')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">notifications</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Utilidade</p>
                      <span>Envie mensagens sobre uma conta ou pedido existente.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-2-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-3'), changeCategory('AUTHENTICATION')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">key</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Autenticação</p>
                      <span>Senhas descartáveis que seus clientes usam para autenticar uma transação ou login.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-3-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>
          </div>
          <h2 class="mb-4">Informações da Campanha</h2>
          <div class="form-group mb-4">
            <label for="wpp-name">{{$tc('app.name', 1)}}:</label>
            <input id="wpp-name" class="form-control" :placeholder="$t('template-component.give-name')" v-model="template.name" @input="checkNameInput" onkeydown="return /[a-z ]/i.test(event.key)" maxlength="510">
            <small class="form-text text-muted">{{ template.name.length }}/512</small>
          </div>
          <!-- Header -->
          <label class="mb-4">Header:</label>
          <div class="header-wrapper">
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.image_type == 'text',}">
                <img src="@/assets/skeleton-text.svg" alt="Skeleton Text" />
                <button class="btn btn-choose-type stretched-link" @click="template.header.type = 'TEXT',
                  template.image_type = 'text',
                  template.header_type = ''">Texto
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.image_type == 'image',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">photo</span>
                </div>
                <button class="btn btn-choose-type stretched-link" @click="template.header_type = 'MIDIA',(template.header.type = 'IMAGE'),
                  (template.image_type = 'image'),
                  (defaultBg = '/assets/img/image_preview_white.png'),
                  (hidePreview = false), callCrop('img-preview', 500, 280, cropActive)">Imagem
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.image_type == 'video',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">video_library</span>
                </div>
                <input ref="uploadedMp4" type="file" accept=".mp4" hidden @change="uploadFileVideo" />
                <button class="btn btn-choose-type stretched-link" @click="template.header_type = 'MIDIA', (template.header.type = 'VIDEO'),
                  (template.image_type = 'video'),
                  (defaultBg = '/assets/img/video_preview_white.png'),
                  (hidePreview = false), openFileUpload()">Vídeo
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.image_type == 'document',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">description</span>
                </div>
                <input ref="uploadedPdf" type="file" accept=".pdf" hidden @change="uploadFilePdf" />
                <button class="btn btn-choose-type stretched-link" @click="template.header_type = 'MIDIA',
                  (template.header.type = 'DOCUMENT'),
                  (template.image_type = 'document'),
                  (defaultBg = '/assets/img/document_preview_white.png'),
                  (hidePreview = false), openFileUploadPdf()">Documento
                </button>
              </b-card-header>
            </b-card>
          </div>
          <div class="form-group mb-4" v-if="template.header.type == 'TEXT'">
            <label for="wpp-name">Texto do header:</label>
            <input id="wpp-name" class="form-control" v-model="template.header.text" maxlength="60">
            <small class="form-text text-muted">{{ template.header.text.length }}/60</small>
          </div>
          <!--/Header -->
          <!-- Body -->
          <div class="form-group mb-4">
            <label>Body: </label>
            <whats-input v-model="template.body" :variables="vars" :paramsChange="paramsChange"/>
            <small class="form-text text-muted">{{ template.body.length }}/1024</small>
            <div class="mt-2" v-if="this.template.body_examples">
              <div class="row" v-if="template.body_examples.length">
                <div class="col">
                  <div class="form-group">
                    <div class="row" v-for="(p, i) in template.body_examples" :key="p.index">
                      <div class="col param-col">
                        <div class="relative d-flex align-items-center flex-wrap">
                          <label>Parâmetro {{ p.index }}</label>
                          <div class="w-100"></div>
                          <input :id="'btn-' + i" class="form-control col-12" type="text" v-model="p.text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 body-example-param">
                  <div class="badge badge-high" v-if="this.template.body_examples.length > 0">
                    Parâmetros de exemplo
                  </div>
                  <button @click="deleteParam" class="btn btn-primary ml-auto">Limpar parametros</button>
                </div>
              </div>
            </div>
          </div>
          <!--/Body -->
          <!-- Footer -->
          <div class="form-group mb-4">
            <label for="wpp-footer">Footer <span>(Opcional)</span></label>
            <input id="wpp-footer" class="form-control" placeholder="Parte inferior do seu modelo de mensagem." v-model="template.footer" @input="checkNameInput" maxlength="60">
            <small class="form-text text-muted">{{ template.footer.length }}/60</small>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-language">Idioma</label>
            <select class="form-control" v-model="template.language">
              <option value="pt_BR">pt_BR</option>
              <option value="en_US">en_US</option>
            </select>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-btn">Buttons <span>(Opcional)</span></label>
            <span class="d-block w-100 mb-1">Crie até 3 botões que permitem que os clientes respondam à sua mensagem ou atuem.</span>
            <select class="form-control" v-model="template.button_type" @change="changeTypeButton">
              <option value="">Nenhum</option>
              <option value="quick_reply">Resposta rápida</option>
              <option value="call_to_action">Call to Action</option>
            </select>
            <!-- Quick reply -->
            <div v-if="template.button_type === 'quick_reply'">
              <div v-for="(button, i) in template.buttons_reply" :key="i" class="m-t-10">
                <span>Texto do botão</span>
                <div class="relative" style="width: 70%">
                  <input class="form-control" v-model="button.text" maxlength="25" />
                    <span class="input-info">{{ button.text.length }}/25</span>
                    <span style="float: right; top: 6px; position: absolute; right: -20px;" class="c-pointer" @click="removeReply(i)" v-if="template.buttons_reply.length > 1">
                      <i class="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!--/Quick reply -->
            <!-- Call to action -->
            <div class="m-t-10" v-if="template.button_type === 'call_to_action'">
              <div class="form-group row rw-buttons-preview" v-for="(button, i) in template.call_to_actions" :key="i">
                <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER' && template.call_to_actions.length > 1 ">
                  <select type="text" class="form-control" v-model="button.type">
                    <option value="PHONE_NUMBER">Telefone</option>
                  </select>
                </div>
                <div class="col-md-4" v-if=" button.type == 'URL' && template.call_to_actions.length > 1 ">
                  <select type="text" class="form-control" v-model="button.type">
                    <option value="URL">Url</option>
                  </select>
                </div>
                <div class="col-md-4" v-if="template.call_to_actions.length < 2">
                  <select v-model="button.type" type="text" class="form-control">
                    <option value="PHONE_NUMBER">
                      {{ $tc('generic-str.tel', 1) }}
                    </option>
                    <option value="URL">Url</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <input type="text" maxlength="20" v-model="button.text" placeholder="Texto" class="form-control" />
                </div>
                <div class="col-md-4" v-if="button.type == 'URL'">
                  <input v-model="button.url" type="text" class="form-control" placeholder="URL" maxlength="1990" />
                </div>
                <div class="col-md-4" v-if="button.type == 'PHONE_NUMBER'">
                  <input type="tel" class="form-control" v-mask="'+55 (##) #####-####'" placeholder="+55 (00) 00000-0000" maxlength="20" v-model="button.phone_number" />
                </div>
              </div>
            </div>
            <!--/Call to action -->
            <div class="m-t-10" v-if="template.button_type === 'quick_reply'">
              <button class="btn btn-info" @click="addQuickReply" :class="{ disabled: template.buttons_reply.length == 3, }">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="m-t-10" v-if="template.button_type === 'call_to_action'">
              <button class="btn btn-info" @click="addCallToButton" :class="{ disabled: template.call_to_actions.length == 2, }">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          <!--/Footer -->
          <div class="btn-wrapper">
            <button class="btn btn-outline-primary">{{$t('app.cancel')}}</button>
            <button @click="saveTemplate" class="btn btn-yup-purple ml-auto"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending, }" :disabled="isSending">
              {{$t('app.save')}} template
            </button>
          </div>
        </div>
        <!--/Content col -->
        <!-- Preview -->
        <div class="col">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div class="card-body">
                <video controls style="width: 100%" v-if="template.image_type == 'video'" ref="previewMp4" :class="{ hide: !hidePreview }">
                  <source />
                </video>
                <iframe src="" id="preview-image-header-pdf" ref="previewPdf" v-if="template.image_type == 'document'" :class="{ hide: !hidePreview }"></iframe>
                <div class="preview-image-header" id="preview-image-header" :style="`background-image: url('${defaultBg}');`" v-if="template.header_type == 'MIDIA' && !hidePreview"></div>
                <h4 class="card-text">
                  {{ template.header.text }}
                </h4>
                <p class="card-text">
                  {{ template.body }}
                </p>
                <p class="card-text footer-item">
                  {{ template.footer }}
                </p>
                <div class="preview-message-btns">
                  <div v-if="template.button_type === 'call_to_action'">
                    <span v-for="(button, i) in template.call_to_actions" :key="i">
                      <i class="fas fa-phone" v-if="button.type == 'PHONE_NUMBER'"></i>
                      <i class="fas fa-edit" v-if="button.type == 'URL'"></i>
                      {{ button.text }}
                    </span>
                  </div>
                  <div class="preview-action-buttons" v-if="template.button_type === 'quick_reply'">
                    <div v-for="(button, i) in template.buttons_reply" :key="i" class="div-reply">
                      {{ button.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview -->
      </div>
    </section>
    <create-template-modal id="create-template-modal" />
    <send-template-modal id="send-template-modal" />
    <preview-modal id="preview-modal" />
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';
import WhatsInput from '@/components/rebranding/whatsapp/WhatsInput.vue';
import WhatsAppService from '@/services/whatsapp.service';
import MCrop from '@/components/rebranding/MCrop.vue';
import BucketService from '@/services/bucket.service';
import axios from '@/plugins/axios';

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
    WhatsInput,
    MCrop,
  },
  data() {
    return {
      text: `
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
        richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
        brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
        tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
        assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
        wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
        vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
        synth nesciunt you probably haven't heard of them accusamus labore VHS.
      `,
      uploadProgress: 0,
      form: {},
      isSending: false,
      fetched: false,
      client: {},
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      cropActive: null,
      cropPreview: null,
      hidePreview: false,
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      file: null,
      template: {
        image_type: 'image',
        header_type: '',
        name: '',
        buttons_reply: [],
        call_to_actions: [],
        button_type: '',
        category: 'MARKETING',
        body: '',
        footer: '',
        body_examples: [],
        url: null,
        header: {
          type: '',
          text: '',
        },
        language: 'pt_BR',
      },
    };
  },
  created() {
    this.template.category = 'MARKETING';
    this.$root.$on('call.crop', this.crop);
  },
  methods: {
    toggleAccordion(id) {
      const input = document.getElementById(`${id}-input`);
      // const checkboxInputs = document.querySelectorAll('.accordion .wpp-input-opt');
      this.$root.$emit('bv::toggle::collapse', id);
      if (input.checked === false) {
        /* [...checkboxInputs].forEach((checkboxInput) => {
          checkboxInput.checked = false;
        }); */
        document.getElementById(`${id}-input`).checked = true;
      } else {
        document.getElementById(`${id}-input`).checked = false;
      }
    },
    checkNameInput() {
      // console.log(`Name input: ${this.template.name.replaceAll(' ', '_')}`);
      this.template.name = this.template.name.replaceAll(' ', '_').toLowerCase();
    },
    changeHeaderType(ev) {
      const type = ev.target.value;
      switch (type) {
        case 'TEXT':
          this.template.header.type = 'TEXT';
          break;
        case 'MIDIA':
          this.template.header.type = 'IMAGE';
          break;
        default:
          break;
      }

      Vue.set(this, 'template', this.template);
    },
    openFileUpload() {
      // this.template.header.type = '';
      this.$refs.uploadedMp4.click();
      // console.log('TesteA: ', this.$refs.uploadedMp4);
    },
    openFileUploadPdf() {
      // this.template.header.type = '';
      this.$refs.uploadedPdf.click();
    },
    uploadFileVideo(event) {
      this.hidePreview = true;
      const file = event.target.files[0];
      this.$refs.previewMp4.src = URL.createObjectURL(file);
      this.$refs.previewMp4.play();
      this.file = file;
    },
    uploadFilePdf(event) {
      this.hidePreview = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      // const preview = this.$refs.previewPdf;
      const preview = document.getElementById('preview-image-header-pdf');
      console.log(preview);
      reader.addEventListener(
        'load',
        () => {
          preview.src = reader.result;
          this.defaultBgPdf = reader.result;
        },
        false,
      );

      preview.addEventListener('load', (ev) => {
        console.log(ev.target.contentDocument);
        const style = document.createElement('style');
        style.textContent = '#viewerContainer { top: 0px !important; }';
        ev.target.contentDocument.head.appendChild(style);
      });

      if (file) {
        reader.readAsDataURL(file);
      }
      this.file = file;
    },
    uploadVideo() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('visibility', 'public');
      formData.append(
        'account_id',
        this.$live.contextApp.$store.state.account.id,
      );
      console.log(this.file);
      Promise.all([
        BucketService.uploadFile(formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            console.log(responses[0].data.url);
            this.template.url = responses[0].data.url;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('visibility', 'public');
      formData.append(
        'account_id',
        this.$live.contextApp.$store.state.account.id,
      );
      console.log(this.file);
      Promise.all([
        BucketService.uploadFile(formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            console.log(responses[0].data.url);
            this.template.url = responses[0].data.url;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, cropActive) {
      // this.template.header.type = '';
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    crop(data) {
      this.cropActive = data;
      this.$root.$emit('show.modal', 'request-modal');
      this.file = data;
      console.log('this.file', this.file);
      document.getElementById(
        'preview-image-header',
      ).style = `background: url(${this.file.url}); background-size: cover;`;
    },
    uploadPicture() {
      const formData = new FormData();
      const filesCrop = [];
      filesCrop.push({
        x: this.file.x,
        y: this.file.y,
        w: this.file.width,
        h: this.file.height,
      });
      formData.append('files[]', this.file.file);
      formData.append('role', 'public');
      formData.append('folder', 'whatsapp');
      formData.append('crop', JSON.stringify(filesCrop));

      Promise.all([BucketService.upload(formData, true, this.callBackProgress)])
        .then(
          (responses) => {
            console.log(responses);
            console.log(
              `${process.env.VUE_APP_API_BUCKET}${responses[0].data[0].url}`,
            );
            this.template.url = `${responses[0].data[0].url}`;
            this.sendRequest();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    changeTypeButton(event) {
      this.template.buttons_reply = [];
      this.template.call_to_actions = [];
      console.log(event.originalTarget.value);
      if (event.originalTarget.value === 'quick_reply') {
        this.template.buttons_reply.push({
          type: 'QUICK_REPLY',
          text: 'Teste',
        });
      }
      if (event.originalTarget.value === 'call_to_action') {
        this.template.call_to_actions.push({
          type: 'PHONE_NUMBER',
          text: 'Telefone',
          phone_number: '(00) 00000-000000',
        });
      }
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    saveTemplate() {
      let errorMessage = null;
      if (this.template.body_examples.length > 0) {
        this.template.body_examples.forEach((param) => {
          if (
            param.text === undefined ||
            param.text === '' ||
            param.text === null
          ) {
            errorMessage = this.$t('template-component.params');
          }
        });
      }
      if (errorMessage) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: errorMessage,
          type: 'danger',
        });
      }

      if (errorMessage == null) {
        this.isSending = true;
        console.log('Template to save');
        console.log(this.template);
        if (this.template.header.type === 'VIDEO') {
          this.uploadVideo();
        }

        if (this.template.header.type === 'IMAGE') {
          this.uploadPicture();
        }

        if (this.template.header.type === 'DOCUMENT') {
          this.uploadFile();
        }

        if (this.template.header.type === 'TEXT') {
          this.sendRequest();
        }

        if (this.template.header.type === '') {
          this.sendRequest();
        }
      }
    },
    sendRequest() {
      WhatsAppService.createTextTemplate(this.template).then(
        (response) => {
          this.$root.$emit('template.submit');
          console.log(response);
          if (response.data.data.statusCode) {
            switch (response.data.data.statusCode) {
              case 400:
                this.$toast.show({
                  title: this.$t('template-component.error'),
                  content: this.$t('template-component.error-2'),
                  type: 'danger',
                });
                break;
              default:
            }
          } else {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('template-component.created'),
              type: 'success',
            });
          }
          this.$router.go(-1);
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
    deleteParam(e) {
      // const index = e;
      // const myField = document.querySelector('.whats-input-text-area');
      /* console.log('paramIndex: ', index);
      console.log('to delete', this.template.body_examples[index]); */
      // this.template.body_examples[index];
      console.log('paramIndex: ', e);
      const index = this.template.body_examples.indexOf(e);
      console.log('To delete: ', index);
      // this.template.body_examples.splice(index, 1); // 2nd parameter means remove one item only
      const regex = new RegExp(`{{(.${e}?)}}`, 'g');
      console.log('Regex value: ', regex);
      /* const regexUpdate = new RegExp('{{(.*?)}}', 'g');
      console.log('Regex update: ', regexUpdate); */
      this.template.body = this.template.body.replace(regex, '');
      /* this.template.body.match(/{{(.*?)}}/g).forEach((element, i) => {
        // Do something with each element
        console.log('IDK: ', element);
      }); */
      /* const newTargetText = this.template.body.replace(/{{(.*?)}}/g, (match, $1) => {
        // Return the replacement leveraging the parameters.
        console.log('IDK2: ', match, 'n: ', $1);
      }); */
      console.log('New value regex: ', this.template.body.replace(regex, ''));
      // this.template.body_examples.splice(index, 1);
      // this.template.params -= 1;

      this.template.body_examples = []; // Delete everything
      this.template.params = 0;
      document.querySelectorAll('.param-col').forEach((el) => el.remove());
    },
    checkParams(e) {
      const countVar = (this.template.body.match(/{{(.*?)}}/g) || []).length;
      console.log('CountVar: ', countVar);
      // this.template.body_examples = [];
      this.template.body_examples.push({
        index: e,
        text: '',
      });
      /*  for (let i = 0; i < countVar; i += 1) {
        console.log('Count', i);
        this.template.body_examples.push({
          index: i + 1,
          text: '',
        });
      } */
    },
    removeCallTo(i) {
      this.template.call_to_actions.splice(i, 1);
    },
    removeReply(i) {
      this.template.buttons_reply.splice(i, 1);
    },
    addQuickReply() {
      if (this.template.buttons_reply.length < 3) {
        this.template.buttons_reply.push({ type: 'QUICK_REPLY', text: '' });
      }
    },
    changeCategory(category) {
      if (this.template.category !== category) {
        this.template.category = category;
      } else {
        this.template.category = '';
      }

      console.log('this.template.category', this.template.category);

      Vue.set(this.template, 'category', this.template.category);
    },
    paramsChange(value) {
      this.template.params = value;
      console.log('ParamsValue: ', this.template.params);
      this.checkParams(this.template.params);
    },
    addCallToButton() {
      if (this.template.call_to_actions.length < 2) {
        let btnAdd = {
          type: 'PHONE_NUMBER',
          text: '',
        };
        this.template.call_to_actions.forEach((btn) => {
          if (btn.type === 'PHONE_NUMBER') {
            btnAdd = {
              type: 'URL',
              text: '',
            };
          }
        });
        this.template.call_to_actions.push(btnAdd);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rw-buttons-preview {
  border-color: #ebedf0 #dfe0e4 #d0d1d5;
  border-width: 1px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: solid 1px #e0e1e1;
  border-radius: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
}
.optional-template-input {
  display: block;
  float: left;
  font-size: 12px;
  background: #f4f4f4;
  padding: 5px;
  border-radius: 10px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.new-template-whats {
  padding: 15px 15px 33px;
  margin: 0 auto;
  background: var(--background-2);
  margin: 10px;
}
.new-template-title {
  color: rgb(28, 30, 33);
}
.darkmode .new-template-title, .darkmode .new-template-whats p span {
  color: #fff;
}
.new-template-whats p span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: left;
  color: rgb(96, 103, 112);
}
.input-info {
  position: absolute;
  right: 10px;
  top: unset;
  color: #aaa;
  bottom: -30px;
}

.input-info.text-area {
  top: 180px;
}

.template-preview-new {
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 15px;
}
.template-preview-new::before {
  background: url('/assets/img/chat-bg.png');
  background-size: auto;
  background-size: 366.5px 666px;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0.06;
  position: absolute;
  top: 0;
  width: 100%;
}
.preview-messages-container {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-left-radius: 0;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
  min-height: 20px;
  position: relative;
  word-wrap: break-word;
  width: 90%;
  padding: 3px;
  margin: 1px;
}
.preview-messages-container::after {
  background: url('/assets/img/chat_buble.png') 50% 50% no-repeat;
  background-size: auto;
  background-size: contain;
  content: '';
  height: 19px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 12px;
}
.time-messages {
  bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  right: 7px;
}

.preview-image-header {
  background-image: url('/assets/img/image_preview_white.png');
  background-size: 80px 80px;
}
.preview-image-header {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-sizing: border-box;
  height: 130px;
}
.preview-message-body {
  color: #262626;
  font-size: 13.6px;
  line-height: 19px;
  padding: 7px 7px 6px 9px;

  & > span {
    white-space: pre-wrap;
  }
}
.preview-message-header {
  color: rgba(0, 0, 0, 0.76);
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  padding: 6px 7px 0 9px;
}
.preview-message-footer {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 17px;
  padding: 0 7px 8px 9px;
}
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}
.preview-action-buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.preview-action-buttons .div-reply {
  z-index: 1024;
  align-items: center;
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 5px;
}
.preview-media-select {
  flex: 0 1 auto;
  order: 0;
  margin-right: 20px;
  align-self: auto;
  background-color: #fff;
  border: 1px solid #dadde1;
  border-radius: 10px;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  height: 160px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  width: 100%;
}
.preview-media-select:hover {
  background-color: #ecf3ff;
  border: 1px solid #0e1421;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-width: 2px;
}
.preview-media-select-active {
  /* background-color: #ecf3ff;
  border: 1px solid #0e1421;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-width: 2px; */
  ::v-deep span {
    color: var(--clr-yup-purple) !important;
  }
}
.preview-media-select label {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 60%;
  display: block;
  margin: 0 auto;
}
.preview-media-select-image label {
  background-image: url('/assets/img/image_preview.png');
}
.preview-media-select-video label {
  background-image: url('/assets/img/video_preview.png');
}
.preview-media-select-document label {
  background-image: url('/assets/img/document_preview.png');
}
.preview-media-select span {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  font-weight: bold;
  overflow-wrap: normal;
  text-align: center;
  display: block;
  margin-top: 8px;
}
.list-group-item:hover {
  background: #ccc;
  cursor: pointer;
}
.list-group-item-active {
  background: #ecebff;
  color: #000;
}
.list-group-item-active strong {
  color: #000;
}
#preview-image-header-pdf {
  width: 100%;
}
#preview-image-header-pdf .toolbar {
  display: none !important;
}
#preview-image-header-pdf #viewerContainer {
  top: 0px !important;
}
#btn-send {
  top: 0px !important;
}

/* Rebranding */
.carousel-visible {
  position: sticky;
  top: 100px;
}

.carousel-template .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep .carousel-control-next-icon,
.carousel-template::v-deep .carousel-control-prev-icon {
  width: 100%;
  height: 45px;
  position: relative;
}

.carousel-template::v-deep .carousel-control-next-icon::after,
.carousel-template::v-deep .carousel-control-prev-icon::after {
  color: var(--clr-yup-purple);
  font-weight: 400;
  font-family: 'Material Icons';
  right: 0;
  top: 10px;
  width: 100%;
}

.carousel-template::v-deep .carousel-control-next-icon::after {
  content: '\e5e1' !important;
}

.carousel-template::v-deep .carousel-control-prev-icon::after {
  content: '\e2ea';
}

.carousel-indicators {
  bottom: -1.5rem;
}

.icon-template span {
  color: var(--clr-yup-purple);
  font-size: 122px;
}

.new-template-whats {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.optional-template-input {
  transform: translateY(-8px) translateX(-5px) scale(0.8);
}

.darkmode .optional-template-input {
  background: var(--background-3);
}

.button-icon {
  height: fit-content;
  padding: 0;
}

.media-icon {
  height: fit-content;
  font-size: 1.75rem;
  transition: 0.5s;
  padding: 9px;
  cursor: pointer;
}

.media-icon.active {
  color: var(--clr-yup-purple);
}

.media-icon:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

::v-deep .body-section .textarea-information {
  display: none;
}

::v-deep body {
  background-color: #fff;
}

.create-template-section {
  background: transparent;
  box-shadow: unset;
}

/* Phone preview */
.preview-div {
  display: flex;
  position: relative;
  /* align-items: center; */
}

.preview-wrapper {
  width: 100%;
  // height: fit-content; // Position-sticky
  height: 100%;
  z-index: 1;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: relative;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }
  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: fit-content;
  }
  .card-preview {
    // position: absolute; // Position-sticky
    position: absolute;
    width: 410px;
    // top: 200px; // Position-sticky
    // right: 20%; // Position-sticky
    // right: 170px;
    // margin-left: auto;
  }
  .card-preview .card-body {
    padding: 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;

    video {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.rebranding .card p.card-text.footer-item {
  color: var(--gray-font-color);
  margin-bottom: 0;
}

.sort-group, .create-group {
  width: auto;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 768px ) and (max-width: 1650px) {
  .card-preview {
    margin-right: auto;
    margin-left: -20px;
  }
}

/* Refact */
.left-content-column {
  > h2{
    font-size: 1rem;
    margin-bottom: 0;
    & ~ span{
      font-size: .75rem;
    }
  }
  .form-group {
    label {
      font-size: 1rem;
      > span {
        color: var(--gray-font-color-3);
      }
    }
    small {
      // margin-left: auto;
      text-align: right;
      color: var(--gray-font-color-3) !important;
    }
  }
}
.accordion-wpp-template {
  margin-top: .5rem;
  margin-bottom: 2rem;
  .card {
    background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: .5rem 1rem;
      background-color: #F2F4F7;
      border-color: #E5E5E5;
    }
  }
  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: .5rem;
    position: relative;
    z-index: 1;
    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }
    .btn-template {
      display: flex;
      height: auto;
      padding: 0;
      font-weight: 400;
      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;
        p.title-opt {
          font-size: .875rem;
          margin-bottom: .25rem;
        }
        span {
          white-space: break-spaces;
          font-size: .75rem;
          color: #595959;
        }
      }
    }
    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;
      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
.header-wrapper {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .card {
    // background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    width: 107px;
    height: 147px;
    /* &.card:not(:first-child) .card-header {
      padding: .85rem .5rem;
    } */
    .card-header {
      height: 100%;
      padding: .85rem .5rem;
      transition: .5s;
      box-shadow: 0px 0px 0px 0px #ffffff00;
      img {
        width: 100%;
        height: 61px;
        object-fit: none;
        object-position: 50% 0px;
      }
      &.active {
        box-shadow: 0px 0px 0px 1px var(--clr-yup-purple);
      }
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F2F2F2;
      width: 100%;
      height: 61px;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }
    }
    .btn-choose-type {
      height: fit-content;
      padding: 0;
      font-weight: 400;
      font-size: .75rem;
    }
  }
}
.body-example-param {
  display: flex;
  flex-direction: column;
  > .badge {
    width: fit-content;
    margin-bottom: .5rem;
    margin-left: auto;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
}
/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}
</style>

<style>
body {
  background-color: var(--background-3);
}

/* body.darkmode {
  background-color: #ffffff !important;
} */
</style>
